import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import LogoMain from './LogoMain';
import LogoIcon from './LogoIcon';
import useAuth from 'hooks/useAuth';
import LogoInvoice from './LogoInvoice';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, isInvoice, sx, to, setMenuSelected }) => {
  const { user } = useAuth();

  const removeMenuSelected = () => setMenuSelected(null);

  return (
    <ButtonBase onClick={removeMenuSelected} disableRipple component={Link} to={!to ? user?.defaultPath : to} sx={sx}>
      {isIcon ? <LogoIcon /> : isInvoice ? <LogoInvoice /> : <LogoMain reverse={reverse} />}
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  isInvoice: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string,
  setMenuSelected: PropTypes.func
};

export default LogoSection;
